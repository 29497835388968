/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.container {
  padding: 20px;
  max-width: 1200px; /* Increase the width of the container */
  margin: 0 auto; /* Center the container */
}

.tab-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-row button {
  background-color: #ddd;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
}

.tab-row button.active {
  background-color: #bbb;
}

#timecodeContainer {
  margin: 20px 0;
  cursor: pointer;
}

#timecode {
  font-size: 36px;
  font-weight: bold;
  background-color: black;
  color: white;
  padding: 20px;
  display: inline-block;
}

#timecode.paused {
  background-color: green; /* Change background to green when paused */
}

#infoDisplay {
  margin: 20px auto;
  border-collapse: collapse;
}

#infoDisplay th, #infoDisplay td {
  padding: 10px;
  border: 1px solid #ccc;
}

.primary-button {
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin: 5px; /* Add margin to each button */
}

.danger-button {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin: 5px; /* Add margin to each button */
}

.half-width {
  width: auto;
  margin-left: 10px; /* Add spacing between label and select */
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.input-row {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center; /* Center the row items */
}

.input-row label {
  margin-right: 10px;
}

.input-row input {
  text-align: center;
  width: 50px;
  padding: 5px;
}

.frame-rate-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.custom-timecode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
}

.custom-timecode-container h3 {
  margin-bottom: 10px;
}

.custom-timecode-container label {
  margin-bottom: 10px;
}

.custom-timecode-container input {
  width: 100px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
}

.label-row {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.divider {
  margin: 20px 0;
  border: 1px solid #ccc;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exit-fullscreen {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px;
}

.fullscreen-content {
  color: white;
}

.fullscreen-timecode {
  font-size: 48px;
}

.fullscreen-info {
  margin-top: 20px;
  font-size: 24px;
}

.paused {
  background-color: #ccc;
}

.info-table {
  margin: 0 auto 20px;
  border-collapse: collapse;
}

.info-table th, .info-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.info-table th {
  background-color: #f4f4f4;
}

.info-table td {
  text-align: center;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Add gap between buttons */
}

.install-button-container {
  margin-top: 20px;
}

.export-button {
  background-color: green;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 20px; /* Add some margin to the top */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* LogTable specific styles */
.log-table {
  margin: 20px auto;
  border-collapse: collapse;
  width: 100%; /* Ensure the log table uses the full width of the container */
}

.log-table th, .log-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.log-table th {
  background-color: #f4f4f4;
}

.log-table td {
  background-color: #fff;
}

.log-table td input {
  width: 100%;
  box-sizing: border-box;
}

.log-table button {
  padding: 5px 10px;
  margin: 5px;
}